<template>
  <section class="relative w-full">
    <div
        class="w-full h-full object-cover absolute inset-0 -z-1"
        style="
                background: radial-gradient(
                    247.42% 100.06% at 32.37% 100%,
                    #ffa17d 5.03%,
                    #ff8050 42.75%,
                    #ed5a22 100%
                );
            "
    ></div>
    <div class="container flex lg:flex-row flex-col overflow-hidden">
      <form @submit.prevent="submitData" class="lg:max-w-115 xl:max-w-130 w-full pt-12.5 lg:py-22 relative">
        <v-text
            data-aos="fade-right"
            data-aos-once="true"
            tag="h2"
            class="text-white font-[550] text-2xl lg:text-30px leading-3xl lg:leading-44px mb-6 md:mb-12"
            default_value="Nội dung hữu ích? Đăng ký để nhận thông tin ngay"
            option_key="newsIndexPage.textSignUp.global"
        />
        <p
            data-aos="fade-up"
            data-aos-once="true"
            class="uppercase text-white/90 leading-6 text-sm mb-3 font-medium"
        >
          {{ t('CONTENT_YOU_WANT_TO_RECEIVE') }}
        </p>

        <div
            data-aos="fade-up"
            data-aos-once="true"
            class="grid grid-cols-2 gap-4 md:flex mb-6 md:mb-12 md:flex-wrap"
        >
          <label
              :for="`checkbox-signup-news-${index}`"
              class="flex items-center gap-1.5 checkbox-custom cursor-pointer"
              v-for="(item, index) in subscriptionCategories"
          >
            <input
                type="checkbox"
                :value="item?.id"
                :id="`checkbox-signup-news-${index}`"
                v-model="infoForm.options"
            />
            <span class="text-white text-17px leading-6 font-[550]">{{ item?.title }}</span>
          </label>
        </div>
        <div
            data-aos="fade-up"
            data-aos-once="true"
            class="relative mb-4 lg:py-1.5 lg:pr-1.5 lg:pl-5.5 pr-1 pl-4 py-1 bg-white rounded-full flex gap-2"
        >
          <input
              type="email"
              :placeholder="t('ENTER_YOUR_EMAIL')"
              @blur="validateEmail"
              class="flex-1 w-full"
              v-model="infoForm.email"
          />

          <button
              class="relative btn-submit-contact bg-accent-01 lg:bg-primary text-white font-semibold uppercase text-sm rounded-30px py-3 px-5 flex items-center justify-center cursor-pointer text-sm min-w-37 min-h-11"
              :class="isLoading ? 'pointer-events-none' : ''"
          >
            <p v-if="!isLoading">{{ $t('REGISTER_NOW') }}</p>
            <div v-else class="absolute loaderForm"></div>
          </button>
          <Transition name="slide-fade">
            <div
                v-if="isNotification"
                class="absolute -bottom-23 z-20 left-0 bg-white shadow-lg py-3 px-4 rounded-lg flex items-center gap-2"
            >
              <div
                  v-if="isError"
                  class="w-5 h-5 bg-red-600 flex flex-col items-center justify-center rounded-full"
              >
                <span class="text-7px font-medium i-custom-close2 text-white"></span>
              </div>

              <div
                  v-else
                  class="w-5 h-5 bg-green-600 flex flex-col items-center justify-center rounded-full"
              >
                <span class="text-base font-medium i-ic:baseline-check text-xs text-white"></span>
              </div>

              <span class="text-sm w-max">{{ notification }}</span>
              <div
                  @click="isNotification = false"
                  class="w-4 h-4 bg-gray-200 hover:bg-gray-300 flex flex-col items-center justify-center rounded-full transform translate-x-2 -translate-y-2 duration-200"
              >
                <span class="i-custom-close2 text-6px text-bw-24 cursor-pointer"></span>
              </div>
            </div>
          </Transition>
        </div>

        <p data-aos="fade-up" data-aos-once="true" class="text-white text-sm leading-6 pl-2 group">
          {{ t('BY_REGISTERING') }}
          <nuxt-link :to="terms_condition" class="duration-500 transform group-hover:font-medium">
            {{ t('TERMS_AND_CONDITIONS') }}
          </nuxt-link>
        </p>
      </form>
      <div class="flex-1 flex justify-center">
        <div class="relative lg:h-full lg:w-full">
          <img
              src="/images/circle/hinh10.svg"
              alt="image"
              class="absolute -bottom-15 -right-30 h-full w-full object-contain lg:block hidden"
          />
          <div class="lg:absolute lg:bottom-0 lg:right-0">
            <div data-aos="fade-right" data-aos-once="true" class="md:h-132 h-94 aspect-86/94">
              <v-image
                  option_key="newsIndexPage.imageSignUp.global"
                  class="w-full h-full object-cover rounded-15px"
                  :width="800"
              >
              </v-image>
            </div>
          </div>
          <img
              src="/images/global/masgotRight.png"
              alt="icon"
              class="w-30 md:w-45 acpect-40/30 object-contain absolute right-0 top-5 md:top-[15%] z-3 animationSway"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import {useReCaptcha} from 'vue-recaptcha-v3'
import {
  NEWSLETTER_SIGN_UP_EVENT_ID,
  NEWSLETTER_SIGN_UP_KEY,
  useEventTracking
} from "../../../composables/ackee/event";

const {getSubscriptionCategories} = useIZNewsDetail()
const {actionPostNewsletter} = useIZNewsIndex()
const {t} = useI18n()

const links_global = inject('globalSettings')?.links[0]

const privacy_policy = ref()
const terms_condition = ref()

const routesRender = () => {
  links_global?.translations.map((item) => {
    if (item?.languages_code == useRoute().params.langCode) {
      privacy_policy.value = item?.privacy_policy
      terms_condition.value = item?.terms_condition
    }
  })
}

const subscriptionCategories = await getSubscriptionCategories(useRoute().params.langCode)

const recaptchaInstance = useReCaptcha()
const notification = ref()
const isNotification = ref(false)
const isError = ref(false)
const isLoading = ref(false)
const infoForm = ref({
  lang: useRoute().params.langCode,
  email: '',
  options: []
})
const validateEmail = () => {
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

  if (!emailRegex.test(infoForm.value.email)) {
    isNotification.value = true
    isError.value = true
    notification.value = t('PLEASE_ENTER_A_VALID_EMAIL_ADDRESS')
    setTimeout(() => {
      isNotification.value = false
    }, 1500)
  } else {
    isError.value = false
  }
}

const recaptcha = async () => {
  await recaptchaInstance?.recaptchaLoaded()
  const token = await recaptchaInstance?.executeRecaptcha('login')
  return token
}

const submitData = async (): Promise<void> => {
  validateEmail()

  if (!isError.value && infoForm.value.options && infoForm.value.options.length > 0 && infoForm.value.email) {
    useEventTracking(NEWSLETTER_SIGN_UP_EVENT_ID, NEWSLETTER_SIGN_UP_KEY)

    notification.value = ''
    isLoading.value = true
    const token = await recaptcha()
    // fetch(`/api/subscribe?response=${token}`, {
    //     method: 'POST',
    //     body: JSON.stringify(infoForm.value)
    // })
    await actionPostNewsletter(token, infoForm.value).then((data) => {
      isLoading.value = false
      if (data.code == 201) {
        infoForm.value.email = ''
        infoForm.value.options = []
        isNotification.value = true
        isError.value = false
        notification.value = t('SUCCESSFULLY_SENT')
        setTimeout(() => {
          isNotification.value = false
        }, 1500)
      }
      if (data.code == 403) {
        infoForm.value.email = ''
        infoForm.value.options = []
        isNotification.value = true
        isError.value = true
        notification.value = t('EMAIL_IS_ALREADY_IN_USE')
        setTimeout(() => {
          isNotification.value = false
        }, 1500)
      }
    })
  } else {
    notification.value = t('NOT_ENOUGH_INFORMATION')
    isNotification.value = true
    isError.value = true
    setTimeout(() => {
      isNotification.value = false
    }, 1500)
  }
}

watch(
    () => useRoute().params.langCode,
    () => {
      routesRender()
    }
)

onMounted(() => {
  routesRender()
})
</script>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.1s ease-out;
}

.slide-fade-leave-active {
  top: auto;
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from {
  transform: translateX(-20px);
  opacity: 0;
}

.slide-fade-leave-to {
  transform: translateX(-20px);
  opacity: 0;
}
</style>
