import {consola} from "consola";
import {queryIZNewsWidgets} from "./news.query";
import {typeQueryOptions} from "../../../shared/base.query";
import {useIZNewsUtils} from "./useIZNewsUtils";
import {TypeNewsDto} from "./news.type";
import {useParseData} from "../../../utils/parse.utils";

export const useIZNewsIndex = () => {

  const globalSettings = inject("globalSettings");
  const {getItems} = useDirectusItems();
  const {generateNewsDto, logger} = useIZNewsUtils(globalSettings);
  const {generateLangSwitch} = useGenerateUrl(globalSettings);
  const {calTotalPage} = useParseData();

  const getLastUpdatedNews = async (langCode: string, options?: typeQueryOptions) => {

    let query = queryIZNewsWidgets({
      filter: {
        _and: [
          {
            status: {
              _eq: "published"
            }
          },
          {
            on_top: false
          }
        ]
      },
      page: options?.page || 1,
      limit: options?.limit || 10,
      meta: ["filter_count"]
    });
    return getItems({
      ...query
    }).then(({data, meta}: any) => {

      const news = data.map((ite: any) => {
        return generateNewsDto(ite, langCode) as TypeNewsDto;
      });
      return {
        news,
        total_page: calTotalPage(+meta.filter_count, options?.limit || 10),
        total_result: meta.filter_count,
        current_page: options?.page || 1,
        lang_switch: generateLangSwitch("news", langCode)
      }
    }).catch((e: any) => {
      logger.error("Error: getLastUpdatedNews: ", e);
      return [];
    })
  }

  const actionPostNewsletter = async (token: any, body: { [key: string]: any }) => {
    body = typeof body === 'string' ? JSON.parse(body) : body

    let data = body.options?.map(ite => ({
      subscribe_id: "+", news_category_id: {id: ite}
    }))
    let bodyData = {
      ...body,
      options: {
        create: data,
        delete: [],
        update: [],
      }
    }

    return $fetch(`/api/subscribe?response=${token}`, {
      method: 'POST',
      body: JSON.stringify(bodyData)
    })
      .then((response: any) => {
        if (
          (response &&
            response.code === 200) || (response &&
            response.code === 201)
        ) {
          return response
        }
      })
      .catch((e) => {
        logger.error('Error actionPostNewsletter: ', e)
        return {}
      })
  }

  return {
    getLastUpdatedNews,
    actionPostNewsletter,
    logger
  }
}
